import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ImageViewer from './ImageViewer';
import styles from './content/Hero.module.postcss';

class TruckHero extends Component {
  render() {
    const { children, photos } = this.props;

    let images = null;
    if (photos) {
      images = typeof photos === 'object' ? photos : [photos];
    }

    return (
      <section className="NewSection">
        <div className={styles.Hero}>
          <div className={styles.Hero_content}>
            {children}
          </div>
          <div className={styles.TruckHero_photos}>
            <ImageViewer images={images} />
          </div>
        </div>
      </section>
    );
  }
}

TruckHero.propTypes = {
  children: PropTypes.node,
  photos: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
};

export default TruckHero;
