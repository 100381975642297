import React from 'react';
import PropTypes from 'prop-types';
import { DiscussionEmbed } from 'disqus-react';

import IsoMorphUtils from '../../modules/isoMorphUtils';

const TruckReviews = ({ cityConfig, truck }) => {
  const disqusConfig = {
    url: IsoMorphUtils.railsContext.href + 'food-trucks/' + truck.id,
    identifier: `sft-truck-${truck.id}`,
    title: truck.name,
  };

  return (
    <DiscussionEmbed shortname={cityConfig.disqus_shortname} config={disqusConfig} />
  );
}

TruckReviews.propTypes = {
  truck: PropTypes.object.isRequired,
  cityConfig: PropTypes.object.isRequired,
};

export default TruckReviews;
