import cn from 'classnames';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Selectors
import { getCityConfig } from '../../../selectors';

import styles from './SearchMap.module.postcss';

class BookAFoodTruck extends PureComponent {
  constructor(props) {
    super(props);

    this.dismiss = this.dismiss.bind(this);

    this.state = {
      dismissed: false,
    };
  }

  dismiss() {
    this.setState({ dismissed: true });
  }

  render() {
    if (this.state.dismissed) {
      return null;
    }
    const { cityConfig } = this.props;

    const classNames = cn(styles.MapWithList_map_cta, {
      [styles.MapWithList_map_cta__sticky]: this.props.sticky,
    });

    return (
      <div className={classNames}>
        <span
          className={styles.MapWithList_map_cta_dismiss}
          onClick={this.dismiss}
          onTouchStart={this.dismiss}
        >×</span>
        <p><strong>Think of us for your next event</strong></p>
        <p>Free no-obligation estimates from {cityConfig.city_name}'s best food {cityConfig.vehicle_type.toLowerCase()}s</p>
        <a
          className="Button"
          href="/catering"
        >
          Food {cityConfig.vehicle_type} Catering
        </a>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

BookAFoodTruck.propTypes = {
  sticky: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookAFoodTruck);